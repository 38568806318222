/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { twMerge } from 'tailwind-merge';
export interface FooterActions {
  data?: any;
  linkClassName?: string;
  imageClassName?: string;
  spanClassName?: string;
  containerClassName?: string;
}

export default function FooterActions({
  data,
  linkClassName,
  imageClassName,
  spanClassName,
  containerClassName
}: FooterActions) {
  const generateHtml = (data: string) => {
    return { __html: data };
  };

  if (
    !data ||
    !data.attributes ||
    !data.attributes.footer_actions ||
    !Array.isArray(data.attributes.footer_actions)
  ) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'mx-[15px] my-10 grid grid-cols-2 gap-2 sm:container md:my-0 md:grid-cols-4 md:gap-x-6 md:px-0 md:pb-[71px] md:pt-[72px]',
        'mb-10 mt-10 md:mb-0',
        containerClassName
      )}
    >
      {data?.attributes?.footer_actions?.map((action, index) => (
        <Link
          key={index}
          href={action?.value?.url}
          className={twMerge(
            'flex h-[98px] w-full flex-col items-center justify-center bg-gray-50 lg:h-[144px]',
            'lg:h-[144px] lg:w-[240px]',
            linkClassName
          )}
        >
          <Image
            src={action?.kwargs?.value?.icon?.url ?? '/noimage.jpg'}
            alt={action?.value?.image_alt}
            aspectRatio={64 / 64}
            className={twMerge(
              'h-[34px] w-[34px] lg:h-16 lg:w-16',
              imageClassName
            )}
            fill
            sizes="(min-width: 768px)"
          />
          <span
            {...(action?.value?.title_color && {
              style: { color: action?.value?.title_color }
            })}
            dangerouslySetInnerHTML={generateHtml(action?.value?.title)}
            className={twMerge(
              'max-w-[150px] text-center text-sm text-primary lg:max-w-none lg:text-xl',
              spanClassName
            )}
          />
        </Link>
      ))}
    </div>
  );
}
