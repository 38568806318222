'use client';

import { FilterableItemSearchType } from './type';
import clsx from 'clsx';
import {
  Select,
  Icon,
  Link,
  SwiperReact,
  SwiperNavigation,
  SwiperSlide
} from '@theme/components';
import { Image } from '@akinon/next/components/image';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export default function DescriptionWithSlider({
  data
}: {
  data: FilterableItemSearchType;
}) {
  const { attributes } = data || {};
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const filteredEvents = attributes?.events
    ?.filter((event) => {
      const matchSearch = event.value.title
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const matchStatus =
        filterStatus === '' ||
        filterStatus === 'all' ||
        event.value.status === filterStatus;
      const matchCategory =
        selectedCategory === '' ||
        selectedCategory === 'all' ||
        event.value.status === selectedCategory;
      return matchSearch && matchStatus && matchCategory;
    })
    ?.reverse();

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleCategoryClick = (value) => {
    setSelectedCategory(value);
    setFilterStatus(value);
  };

  const eventStatus = Array.isArray(attributes?.dropdown)
    ? attributes?.dropdown.map((item) => ({
        value: item.value.value,
        label: item.value.label
      }))
    : [];

  const categories = Array.isArray(attributes?.dropdown)
    ? attributes?.dropdown
    : [];

  return (
    <>
      <div className=" border-b border-lightBlue">
        <div className="container mb-6 mt-4">
          <SwiperReact
            slidesPerView="auto"
            className="w-full"
            spaceBetween={0}
            modules={[SwiperNavigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
          >
            {categories?.map((item, index) => (
              <SwiperSlide
                key={index}
                className="!w-[182px] border-r px-4 last:border-none lg:!w-[248px]"
              >
                <div
                  className="flex cursor-pointer flex-col items-center"
                  onClick={() => handleCategoryClick(item.value.value)}
                >
                  <Image
                    src={item?.kwargs?.value?.image?.url}
                    alt={item?.value?.alt}
                    aspectRatio={1}
                    width={80}
                    height={80}
                    className="max-w-[80px]"
                    imageClassName="rounded-full"
                  />
                  <div className="mt-2 leading-[24px] text-primary">
                    {item?.value?.label}
                  </div>
                </div>
              </SwiperSlide>
            ))}{' '}
            <div
              className={clsx(
                `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                'lg:bg-primary lg:after:text-white'
              )}
            ></div>
            <div
              className={clsx(
                `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                `after:!text-[.75rem] after:text-primary after:content-['next']`,
                'lg:bg-primary lg:after:text-white'
              )}
            ></div>
          </SwiperReact>
        </div>
      </div>
      <div className="container">
        <div className="my-4 flex flex-row gap-6">
          <div className="relative flex h-10 w-full items-center gap-4 bg-gray-50 px-6 ring-primary lg:w-[500px] [&:has(:focus-visible)]:ring-1">
            <Icon className="text-gray-700" name="search" size={24} />
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={attributes?.search_text?.value}
              className="inset-0 h-full w-full border-0 bg-gray-50 bg-transparent outline-0 placeholder:text-ellipsis placeholder:text-gray-600"
            />
          </div>
          <div className="h-10 w-[1px] bg-lightBlue"></div>
          <div>
            <Select
              options={eventStatus}
              onChange={handleFilterChange}
              value={filterStatus}
              className="h-[40px] w-[160px] border border-gray-700 leading-[1.43] text-gray-700 lg:w-[190px] lg:max-w-[190px]"
            ></Select>
          </div>
        </div>
        <hr className="ml-[calc(calc(-100vw_-_1296px)_/_2)] w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />

        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
          {filteredEvents?.map((event, index) => {
            return (
              <div key={index} className={twMerge('flex flex-col pt-4')}>
                <Link href={event?.value?.url}>
                  <Image
                    imageClassName="w-full"
                    className="mb-2 hidden object-cover object-center lg:block"
                    alt={event?.value?.alt}
                    src={event?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                    aspectRatio={1 / 1}
                    sizes="343px"
                    fill
                  />
                  <Image
                    imageClassName="w-full"
                    className="mb-2 object-cover object-center lg:hidden"
                    alt={event?.value?.alt}
                    src={
                      event?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'
                    }
                    aspectRatio={416 / 416}
                    sizes="416px"
                    fill
                  />
                </Link>

                {event?.value?.badge_text && (
                  <span
                    className={clsx(
                      'mb-2 w-fit bg-secondary-100 px-2 py-1 text-xs leading-[1.5] text-secondary-700',
                      'lg:px-3 lg:py-[6px] lg:text-sm lg:leading-[1.43]'
                    )}
                  >
                    {event?.value?.badge_text}
                  </span>
                )}
                {event?.value?.title && (
                  <Link
                    href={event?.value?.url}
                    className={clsx(
                      'mb-2 text-lg font-medium leading-[1.56]',
                      'lg:text-2xl lg:leading-[1.33]'
                    )}
                  >
                    {event.value.title}
                  </Link>
                )}
                {event?.value?.content && (
                  <div
                    className={clsx(
                      'text-sm leading-[1.43]',
                      'lg:text-base lg:leading-[1.5]'
                    )}
                  >
                    {event.value.content}
                  </div>
                )}
                <div className="mt-auto">
                  {index % 3 === 0 && (
                    <hr className="ml-[calc(calc(-100vw_-_1296px)_/_2)] mt-10 hidden w-[calc(calc(100vw_+_1296px))] border-lightBlue-500 lg:block" />
                  )}
                  <hr className="col-[1_/_4] ml-[-16px] mt-10 block h-px w-[calc(100%_+_32px)] border-lightBlue-500 lg:hidden" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
