import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useLocalization } from '@akinon/next/hooks';

interface passwordType {
  password: string;
  isVisible: boolean;
}

const PasswordRulesFeedback = (Props: passwordType) => {
  const { password, isVisible } = Props;
  const { t } = useLocalization();

  const rules = [
    {
      rule: /.{8,}/,
      description: 'auth.register.form.input_constraints.password_length'
    },
    {
      rule: /[A-Z]/,
      description: 'auth.register.form.input_constraints.uppercase_letter'
    },
    {
      rule: /[a-z]/,
      description: 'auth.register.form.input_constraints.lower_case'
    },
    {
      rule: /[0-9]/,
      description: 'auth.register.form.input_constraints.numeric_character'
    },
    {
      rule: /[^a-zA-Z0-9\s]/,
      description: 'auth.register.form.input_constraints.special_character'
    }
  ];

  const passwordCheck =
    password && rules.some((item) => !password.match(item.rule));

  return (
    <p
      className={twMerge(
        'hidden text-sm text-gray-850',
        isVisible ? 'flex flex-wrap' : !passwordCheck ? 'hidden' : ''
      )}
    >
      <span> {t('auth.register.form.password.info_start')}</span>
      &nbsp;
      {rules.map((item, index) => (
        <span
          key={index}
          className={twMerge(
            'whitespace-nowrap text-sm',
            password.match(item.rule) ? 'valid text-success' : !password.match(item.rule) && password ? 'text-error' : 'text-primary'
          )}
        >
          {t(`${item.description}`)}
          &nbsp;
        </span>
      ))}
      <span>{t('auth.register.form.password.info_end')}</span>
    </p>
  );
};

export default PasswordRulesFeedback;
