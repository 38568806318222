'use client';
import { Image } from '@akinon/next/components/image';
import { BlogPageType } from './type';
import { usePathname, useSearchParams } from 'next/navigation';
import { Link, Icon } from '@theme/components';
import clsx from 'clsx';

export const BlogPageWidgetOrder = ({ data }: { data: BlogPageType }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const URL = process.env.NEXT_PUBLIC_URL;
  const currentUrl = URL + pathname + searchParams;

  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return;

  const first_section = attributes?.first_section;
  const second_section = attributes?.second_section;
  const third_section = attributes?.third_section;
  const third_section_mobile = attributes?.third_section_mobile;
  const fourth_section = attributes?.fourth_section;
  const prev_blog = attributes?.prev_blog;
  const next_blog = attributes?.next_blog;

  return (
    <div>
      {Object.keys(first_section || {}).length > 0 && (
        <div className="mb-10 border-b border-lightBlue">
          <div className="container flex h-full w-full flex-col justify-between gap-10 pb-10 lg:flex-row lg:gap-12">
            {first_section?.value?.text && (
              <p
                className={clsx('w-full text-base', first_section?.kwargs?.value?.image ? 'lg:max-w-[698px]' : 'lg:max-w-full')}
                dangerouslySetInnerHTML={{
                  __html: first_section?.value?.text
                }}
              />
            )}
            {first_section?.kwargs?.value?.image && (
              <Image
                alt="first_section_img"
                src={first_section?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                width={550}
                height={300}
                className="hidden justify-center object-cover lg:flex"
                aspectRatio={550 / 300}
              />
            )}
            {first_section?.kwargs?.value?.mobile_image && (
              <Image
                alt="first_section_img"
                src={
                  first_section?.kwargs?.value?.mobile_image?.url ??
                  '/noimage.jpg'
                }
                width={500}
                height={250}
                className="block w-full lg:hidden"
                aspectRatio={500 / 250}
              />
            )}
          </div>
        </div>
      )}
      {second_section?.length > 0 && (
        <div className="mb-10 border-b border-lightBlue lg:mb-[72px]">
          <div className="container flex flex-col gap-10 pb-10 lg:gap-[72px] lg:pb-[72px]">
            {second_section?.map((section, index) => (
              <div key={index} className="flex flex-col gap-6">
                <h2 className="text-xl leading-[30px] lg:text-[32px] lg:leading-[44px]">
                  {section?.value?.title}
                </h2>
                <div
                  className="text-sm lg:text-base"
                  dangerouslySetInnerHTML={{ __html: section?.value?.content }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {Object.keys(third_section || {}).length > 0 && (
        <div className="mb-10 border-b border-lightBlue lg:mb-[72px]">
          <div className="container pb-10 lg:pb-[72px]">
            <Image
              alt="third_section_img"
              src={third_section?.kwargs?.url ?? '/noimage.jpg'}
              sizes="1296px"
              width={1296}
              height={480}
              className="hidden w-full lg:block "
              imageClassName="aspect-[343/190] w-full lg:aspect-[1296/480] object-cover"
            />
          </div>
        </div>
      )}
      {Object.keys(third_section_mobile || {}).length > 0 && (
        <div className="mb-10 border-b border-lightBlue lg:mb-[72px]">
          <div className="container pb-10 lg:pb-[72px]">
            <Image
              alt="third_section_img"
              src={third_section_mobile?.kwargs?.url ?? '/noimage.jpg'}
              sizes="343px"
              width={343}
              height={190}
              className="block w-full lg:hidden"
              imageClassName="aspect-[343/190] w-full lg:aspect-[1296/480] object-cover"
            />
          </div>
        </div>
      )}
      {fourth_section?.length > 0 && (
        <div className="mb-10 border-b border-lightBlue lg:mb-[72px]">
          <div className="container flex flex-col gap-10 pb-10 lg:gap-[72px] lg:pb-[24px]">
            {fourth_section?.map((section, index) => (
              <div key={index} className="flex flex-col gap-6">
                <h2 className="text-xl leading-[30px] lg:text-[32px] lg:leading-[44px]">
                  {section?.value?.title}
                </h2>
                <div
                  className="text-sm lg:text-base"
                  dangerouslySetInnerHTML={{ __html: section?.value?.content }}
                />
              </div>
            ))}
            <div className="flex justify-start gap-6 lg:justify-end">
              <Link
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="facebook" size={32} />
              </Link>
              <Link
                href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" size={32} />
              </Link>
              <Link
                href={`https://www.youtube.com/watch?v=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="youtube" size={32} />
              </Link>
              <Link
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="twitter-new" size={32} />
              </Link>
            </div>
          </div>
        </div>
      )}
      {(Object.keys(prev_blog || {}).length > 0 ||
        Object.keys(next_blog || {}).length > 0) && (
          <div className="lg:mb-[72px] lg:border-b lg:border-lightBlue">
            <div className="mb-10 bg-lightBlue-50 lg:mb-[72px]">
              <div className="container flex flex-col items-center gap-0 py-10 lg:flex-row lg:justify-between lg:gap-[72px] lg:py-[72px]">
                {Object.keys(prev_blog || {}).length > 0 && (
                  <div className="flex flex-col border-b border-lightBlue pb-[60px] lg:max-w-[440px] lg:border-none lg:pb-0">
                    {prev_blog?.value?.category && (
                      <p className="mb-1 text-base lg:text-lg">
                        {prev_blog?.value?.category}
                      </p>
                    )}
                    {prev_blog?.value?.title && (
                      <h2 className="text-lg font-medium leading-[1.56] lg:text-2xl lg:leading-[1.33]">
                        {prev_blog?.value?.title}
                      </h2>
                    )}
                    <Link
                      href={prev_blog?.value?.button_link ?? '#'}
                      className="mt-10 flex items-center justify-start"
                    >
                      <Icon name="arrow-left" size={16} className="mr-2" />
                      {prev_blog?.value?.button_text && (
                        <span className="text-base underline">
                          {prev_blog?.value?.button_text}
                        </span>
                      )}
                    </Link>
                  </div>
                )}
                {Object.keys(next_blog || {}).length > 0 && (
                  <div className="flex flex-col pt-[60px] lg:max-w-[440px] lg:pt-0">
                    {next_blog?.value?.category && (
                      <p className="mb-1 text-base lg:text-lg">
                        {next_blog?.value?.category}
                      </p>
                    )}
                    {next_blog?.value?.title && (
                      <h2 className="text-lg font-medium leading-[1.56] lg:text-2xl lg:leading-[1.33]">
                        {next_blog?.value?.title}
                      </h2>
                    )}
                    <Link
                      href={next_blog?.value?.button_link ?? '#'}
                      className="mt-10 flex items-center justify-end"
                    >
                      {next_blog?.value?.button_text && (
                        <span className="text-base underline">
                          {next_blog?.value?.button_text}
                        </span>
                      )}
                      <Icon name="arrow-right" size={16} className="ml-2" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default BlogPageWidgetOrder;
