import { Product } from '@akinon/next/types/commerce/product';
import { getCookie } from '@akinon/next/utils';
import settings from 'settings';
export const generateJsonLd = (product: Product) => {
  const URL = process.env.NEXT_PUBLIC_URL;

  const productJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.name,
    image: product.productimage_set?.map((item) => item.image),
    description: product?.attributes?.description,
    sku: product.sku,
    mpn: product.sku,
    brand: {
      '@type': 'Brand',
      name: 'Paşabahçe Mağazaları'
    },

    offers: {
      '@type': 'Offer',
      url: `${URL + product.absolute_url}`,
      priceCurrency: product.currency_type,
      price: product.price,
      itemCondition: 'https://schema.org/NewCondition',
      availability: product.in_stock
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock'
    }
  };

  return productJsonLd;
};

export const generateBlogJsonLd = (title: string) => {
  const blogJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: title
  };

  return blogJsonLd;
};

export const generateBreadcrumbJsonLd = (
  breadcrumbList?: { label: string; url: string }[],
  product?: Product | null
) => {
  const defaultLocale = settings?.localization?.defaultLocaleValue;

  const BASEURL =
    process.env.NEXT_PUBLIC_URL +
    (getCookie('pz-locale') && getCookie('pz-locale') != defaultLocale
      ? `/` + getCookie('pz-locale')
      : '');

  const productJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': '#WebPage',
    url: `${BASEURL}`,
    name: 'Paşabahçe Mağazaları',
    breadcrumb: {
      '@type': 'Breadcrumblist',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Anasayfa',
          item: `${BASEURL}`
        }
      ]
    }
  };

  if (breadcrumbList) {
    breadcrumbList?.forEach((item, index) => {
      productJsonLd?.breadcrumb?.itemListElement.push({
        '@type': 'ListItem',
        position: index + 2,
        name: item?.label,
        item: `${BASEURL + item?.url}`
      });
    });
  }

  if (product) {
    productJsonLd?.breadcrumb?.itemListElement.push({
      '@type': 'ListItem',
      position: breadcrumbList?.length + 2,
      name: product?.name,
      item: `${BASEURL + product?.absolute_url}`
    });
  }

  return productJsonLd;
};
