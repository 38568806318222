import { Image } from '@akinon/next/components/image';

type Props = {
  src: string;
  alt: string;
};

export default function VerticalImage({ src, alt }: Props) {
  return (
    <>
      <Image
        src={src}
        alt={alt}
        width={324}
        height={576}
        imageClassName="h-[576px] max-h-[576px] w-full"
        className="hidden md:block"
      />
      <Image
        src={src}
        alt={alt}
        width={343}
        height={610}
        className="md:hidden"
      />
    </>
  );
}
