'use client';

import { Image } from '@akinon/next/components/image';
import { SendYourCvType, SendYourCvFormType } from './type';
import { Button, Icon, Input, FileInput, Modal } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import { useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PhoneInput } from '@theme/components/phone-input';
import { countries } from '@theme/countries';

const sendToCvFormSchema = (t, { phoneNumberLength }) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t('flat_pages.career.form.errors.required'))
      .min(2, t('flat_pages.career.form.errors.min', { min: 2 }))
      .max(50, t('flat_pages.career.form.errors.max', { max: 50 }))
      .matches(
        /^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/,
        t('flat_pages.career.form.errors.alfabetic')
      ),
    surname: yup
      .string()
      .required(t('flat_pages.career.form.errors.required'))
      .min(2, t('flat_pages.career.form.errors.min'))
      .max(50, t('flat_pages.career.form.errors.max'))
      .matches(
        /^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]+$/,
        t('flat_pages.career.form.errors.alfabetic')
      ),
    email: yup
      .string()
      .email(t('flat_pages.career.form.errors.email'))
      .required(t('flat_pages.career.form.errors.required')),
    phone: yup
      .string()
      .transform((value) => value.replace(/[^0-9]/g, ''))
      .length(phoneNumberLength, t('flat_pages.career.form.errors.phone'))
      .required(t('flat_pages.career.form.errors.required')),
    file: yup.mixed()
  });

export default function CampusAndInternshipWidget({
  data
}: {
  data: SendYourCvType;
}) {
  const attributes = data?.attributes;

  const { t, locale } = useLocalization();
  const [_, currentCountryValue] = locale.split('-');

  const currentCountry = countries.find(
    (country) => country.value === currentCountryValue
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<SendYourCvFormType>({
    resolver: yupResolver(
      sendToCvFormSchema(t, {
        phoneNumberLength: currentCountry?.phoneLength
      })
    )
  });

  const fileInput = useRef(null);
  const fileNameView = useRef(null);

  const mainTitle = attributes?.main_title?.value;
  const mainDescription = attributes?.main_description?.value;
  const image = attributes?.image?.kwargs?.url;

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [file, setFile] = useState<File>();

  const handleFileChange = (e) => {
    const inputElement = e.target as HTMLInputElement;
    const name = inputElement.files[0].name;

    setFile(inputElement.files[0]);

    if (fileNameView && fileNameView.current) {
      fileNameView.current.innerText = name;
    }
  };

  const onSubmit: SubmitHandler<SendYourCvFormType> = async (data) => {
    const formData = new FormData();

    formData.append('full_name', `${data.name} ${data.surname}`);
    formData.append('email', data.email);
    formData.append('phone', `${currentCountry.areaCode}${data.phone}`);
    formData.append('file', file);
    formData.append('message', 'CV');
    formData.append('subject', '1');

    try {
      const request = await fetch('/api/contact-us', {
        method: 'POST',
        body: formData
      });
      const response = await request.json();

      if (response.data) {
        reset();
        setSuccessModalOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mt-10 border-b border-lightBlue  bg-gray-100 py-10 font-sans text-primary md:mt-[4.375rem]">
      <div className="container md:grid md:grid-cols-[1fr_34.375rem]">
        <div className="flex flex-col gap-4 md:bg-white md:p-10">
          <div className="flex flex-col gap-2">
            {mainTitle && (
              <div className="text-lg font-medium leading-6 md:text-2xl  md:leading-snug">
                {mainTitle}
              </div>
            )}
            {mainDescription && (
              <div
                className="text-sm leading-snug md:text-base"
                dangerouslySetInnerHTML={{ __html: mainDescription }}
              ></div>
            )}
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-4 flex flex-col gap-6"
          >
            <div className="grid grid-cols-2 gap-4">
              <Input
                labelStyle="default"
                data-testid="cv-name"
                label={t('flat_pages.career.form.name')}
                placeholder={t('flat_pages.career.form.name_placeholder')}
                className="border-lightBlue-600 !text-base"
                inputClassName="!px-4 placeholder:!text-sm"
                labelClassName="text-gray !mb-[6px] font-medium"
                errorClassName="!mt-[6px]"
                {...register('name')}
                error={errors.name}
                required
              />
              <Input
                name="surname"
                labelStyle="default"
                data-testid="cv-surname"
                label={t('flat_pages.career.form.surname')}
                placeholder={t('flat_pages.career.form.surname_placeholder')}
                className="border-lightBlue-600 !text-base"
                inputClassName="!px-4 placeholder:!text-sm"
                labelClassName="text-gray !mb-[6px] font-medium"
                errorClassName="!mt-[6px]"
                {...register('surname')}
                error={errors.surname}
                required
              />
            </div>
            <Input
              name="email"
              labelStyle="default"
              data-testid="cv-email"
              label={t('flat_pages.career.form.email')}
              placeholder={t('flat_pages.career.form.email_placeholder')}
              className="border-lightBlue-600 ps-4 !text-base"
              errorClassName="!mt-[6px]"
              labelClassName="text-gray !mb-[6px] font-medium"
              inputClassName="placeholder:!text-sm"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {...register('email')}
              error={errors.email}
              required
            />
            <PhoneInput
              name="phone"
              labelStyle="default"
              data-testid="cv-phone"
              label={t('flat_pages.career.form.phone')}
              className="border-lightBlue-600 ps-4 !text-base"
              errorClassName="!mt-[6px]"
              labelClassName="text-gray !mb-[6px] font-medium"
              inputClassName="placeholder:!text-sm"
              format="0 (###) ### ## ##"
              mask="_"
              control={control}
              {...register('phone')}
              error={errors.phone}
              required
              allowEmptyFormatting={true}
            />
            <div>
              <div className="mb-1.5 text-sm font-medium leading-snug text-gray">
                {t('flat_pages.career.form.resume')}
              </div>
              <div className="relative grid h-32 w-full grid-cols-2 border border-dashed border-lightBlue-600 px-3.5 py-2">
                <div className="flex flex-col justify-center">
                  <div
                    ref={fileNameView}
                    className="flex items-center text-sm text-gray-850"
                  >
                    {t('flat_pages.career.form.resume_placeholder')}
                  </div>
                  <FileInput
                    ref={fileInput}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                    className="top-o absolute left-0 h-full w-full cursor-pointer opacity-0"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="flex items-center justify-end">
                  <Button
                    type="button"
                    className="flex !w-[9.563rem] items-center gap-2 !px-2"
                    onClick={() => {
                      if (fileInput && fileInput.current) {
                        fileInput.current.click();
                      }
                    }}
                  >
                    <Icon name="plus" size={13} />
                    {t('flat_pages.career.form.resume_btn')}
                  </Button>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              data-testid="cv-submit"
              className="flex !h-12 w-full cursor-pointer items-center justify-center gap-2 !border-none !bg-black !text-base leading-6 md:w-auto md:!text-white"
            >
              {t('flat_pages.career.form.submit')}
            </Button>
          </form>
        </div>
        {image && (
          <Image
            imageClassName="w-full object-cover"
            className={'hidden md:block'}
            alt={mainTitle}
            src={image}
            width={550}
            height={568}
          />
        )}
      </div>

      <Modal
        portalId="send-your-cv-form-success-modal"
        open={isSuccessModalOpen}
        title={t('account.contact.form.success.title')}
        setOpen={(state) => {
          setSuccessModalOpen(state);
        }}
        titleClassName="text-2xl font-medium leading-8"
        className="bottom-0 top-auto w-full max-w-lg translate-y-0 p-6 md:bottom-auto md:top-1/2 md:-translate-y-1/2"
        classNameHeader="p-0 pb-4 border-b-0"
      >
        <div className="max-h-[85vh] overflow-y-auto pb-8">
          <div>{t('account.contact.form.success.description')}</div>
        </div>
        <Button
          onClick={() => setSuccessModalOpen(false)}
          className="h-12 w-full px-4 py-3 font-medium"
        >
          {t('account.contact.form.success.button')}
        </Button>
      </Modal>
    </div>
  );
}
