/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import {
  Accordion,
  Icon,
  Input,
  TabPanel,
  Tabs,
  Link
} from '@theme/components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocalization } from '@akinon/next/hooks';
import clsx from 'clsx';
import { ROUTES } from '@theme/routes';
import { useInView } from 'react-intersection-observer';
import './index.scss';
import { generateBreadcrumbJsonLd } from '@theme/utils/generate-jsonld';

export default function FAQTabsAndContent({ data }: { data: any }) {
  const attributes = data?.attributes;
  const faqTabsAndContents = attributes?.faq_tabs_and_contents;

  const { t } = useLocalization();
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState({
    title: '',
    question: ''
  });

  const groupedFaqData = useMemo(() => {
    return faqTabsAndContents?.reduce((acc, item) => {
      const tab = item?.value?.title;

      if (!acc[tab]) {
        acc[tab] = [];
      }

      acc[tab].push(item);

      return acc;
    }, {});
  }, [faqTabsAndContents]);

  const filteredFaqData = useMemo(() => {
    return faqTabsAndContents?.filter((t) =>
      t?.value?.question.toLowerCase().includes(searchText?.toLowerCase())
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, data]);

  const preSelectedTabIndex = Object.keys(groupedFaqData).findIndex(
    (tabTitle) => tabTitle == activeTab?.title
  );

  useEffect(() => {
    if (searchInputRef.current && isOpenSearchModal) {
      searchInputRef.current.focus();
    }
  }, [isOpenSearchModal]);

  const { ref: faqContentRef, inView: faqContentInView } = useInView({
    rootMargin: '-40px'
  });
  const { ref: faqTabsRef, inView: faqTabsInView } = useInView();

  const breadcrumbJsonLd = generateBreadcrumbJsonLd([
    {
      label: t('account.base.menu.my_account'),
      url: ROUTES.ACCOUNT
    },
    {
      label: t('account.base.menu.faq'),
      url: ROUTES.ACCOUNT_FAQ
    }
  ]);

  if (Object.keys(attributes || {}).length === 0) return;

  if (!Array.isArray(faqTabsAndContents) || !faqTabsAndContents.length) return;

  return (
    <div className="container pb-10 lg:pb-[72px]">
      <div className="mb-6 flex w-full items-center gap-2 lg:mb-8">
        <Link className={clsx('hidden')} href={ROUTES.ACCOUNT}>
          <Icon name="arrow-left" size={32} />
        </Link>
        <h3
          className={clsx(
            'text-lg font-medium',
            'lg:text-2xl',
            'text-xl leading-[1.5] lg:text-[32px] lg:leading-[1.38]'
          )}
        >
          {t('account.faq.header.title')}
        </h3>

        <div
          className={clsx('ml-auto mr-0  bg-white', {
            'fixed left-0 top-10 z-40 h-screen w-screen lg:static lg:h-auto lg:w-auto':
              isOpenSearchModal
          })}
        >
          <div
            className={clsx(
              'flex items-center gap-2 border-b border-b-lightBlue-500 p-4 lg:hidden',
              { hidden: !isOpenSearchModal }
            )}
          >
            <Icon
              onClick={() => setIsOpenSearchModal(false)}
              name="arrow-left"
              size={32}
            />
            <h3 className="text-sm ">{t('account.faq.header.title')}</h3>
          </div>
          <div className={clsx('relative', { 'px-4': isOpenSearchModal })}>
            <div
              className={clsx(
                'relative flex h-10 w-10 items-center justify-center  border border-transparent bg-gray-50 [&:has(:focus)]:border [&:has(:focus)]:border-primary',
                'lg:h-auto  lg:w-[367px] lg:pl-6',
                {
                  'mb-4 mt-6 w-full py-4 pl-3 pr-4 lg:mb-0 lg:mt-0 lg:py-0 lg:pr-0':
                    isOpenSearchModal
                }
              )}
            >
              <Icon
                onClick={() => {
                  setIsOpenSearchModal(true);
                  searchInputRef?.current?.focus?.();
                }}
                name="search"
                className="text-gray-850"
                size={24}
              />
              <Input
                ref={searchInputRef}
                wrapperClassName={clsx('w-full', {
                  'hidden lg:block': !isOpenSearchModal
                })}
                value={searchText}
                onChange={(e) => setSearchText(e.currentTarget.value)}
                inputClassName="w-full"
                className={clsx(
                  'w-full border-0 bg-transparent px-3 !text-base',
                  'placeholder:text-base placeholder:text-gray-800',
                  searchText ? 'pr-[36px] lg:pr-16' : 'pr-0 lg:pr-6'
                )}
                placeholder={t('account.faq.search.placeholder')}
              />
              {searchText && (
                <Icon
                  onClick={() => {
                    setSearchText('');
                  }}
                  name="close"
                  className={clsx(
                    'absolute  right-4 cursor-pointer text-gray-850',
                    'lg:right-6 lg:block',
                    { hidden: !isOpenSearchModal }
                  )}
                  size={24}
                />
              )}
            </div>

            {searchText && filteredFaqData.length > 0 && (
              <div
                className={clsx(
                  'faq-search-results',
                  'hidden max-h-[calc(100dvh-186px)] w-full overflow-auto overscroll-contain bg-white',
                  'lg:absolute lg:block lg:max-h-[335px] lg:max-w-[367px] lg:border lg:border-lightBlue-600 lg:bg-gray-50 lg:px-4',
                  {
                    '!block': isOpenSearchModal
                  }
                )}
              >
                {filteredFaqData?.map((item, index) => {
                  const isIncludeSearchKey = item?.value?.question
                    .toLowerCase()
                    .includes(searchText.toLowerCase());

                  let questionLabel = item?.value?.question;

                  if (isIncludeSearchKey) {
                    questionLabel = questionLabel?.replace(
                      new RegExp(
                        searchText
                          .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                          .toLowerCase(),
                        'gi'
                      ),
                      (match) =>
                        `<strong class="text-primary font-semibold">${match}</strong>`
                    );
                  }

                  return (
                    <div
                      tabIndex={0}
                      onClick={() => {
                        setActiveTab({
                          title: item?.value?.title,
                          question: item?.value?.question
                        });
                        setSearchText('');
                        isOpenSearchModal && setIsOpenSearchModal(false);
                      }}
                      key={`faq-search-item-${index}`}
                      className="cursor-pointer py-2.5 text-sm"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: questionLabel
                        }}
                      ></div>{' '}
                      <u className="font-semibold">{item?.value?.title}</u>
                    </div>
                  );
                })}
                {filteredFaqData.length === 0 && (
                  <div className="pt-4 text-center text-sm text-gray-800">
                    {t('account.faq.search.not_found')}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={faqContentRef}>
        <Tabs
          tabsWrapperRef={faqTabsRef}
          preSelectedTabIndex={preSelectedTabIndex}
          className={clsx(
            '[&>.tabs-wrapper]:min-h-20 lg:[&>.tabs-wrapper]:min-h-max'
          )}
          tabsWrapperClassName={clsx(
            'hide-overflow-mobile !mb-10 -ml-4 w-[calc(100%+32px)] !snap-none !gap-0 px-4 pb-0',
            'lg:static lg:!mb-8 lg:ml-0 lg:w-auto lg:px-0 lg:py-0',
            {
              'fixed top-10 w-full bg-white py-3 ':
                !faqTabsInView && faqContentInView
            }
          )}
        >
          {Object.keys(groupedFaqData)?.map((tabTitle, index) => (
            <TabPanel
              onClick={() =>
                setActiveTab({
                  title: '',
                  question: ''
                })
              }
              tabClassName="pt-0 normal-case"
              key={index}
              title={tabTitle}
            >
              {groupedFaqData[tabTitle]?.map((faq, index) => {
                return (
                  <Accordion
                    key={`faq-item-${index}`}
                    isCollapse={faq?.value?.question == activeTab?.question}
                    activeClassName="bg-gray-100"
                    iconSize={14}
                    iconClassName="min-w-8 min-h-8 ml-4 grid place-items-center bg-primary text-white rounded-full mb-3 lg:mb-4"
                    childrenClassName="m-0 px-0 border-t border-lightBlue-500"
                    titleClassName="text-sm font-medium mb-3 lg:text-lg lg:mb-4"
                    className="mb-6 border border-lightBlue-500 p-0 px-0 pt-3 last:mb-0 lg:pt-4 [&>div]:px-3 lg:[&>div]:px-4"
                    title={faq?.value?.question}
                  >
                    <div
                      className="py-3 text-xs leading-[1.5] lg:py-4 lg:text-sm"
                      dangerouslySetInnerHTML={{
                        __html: faq?.value?.answer
                      }}
                    ></div>
                  </Accordion>
                );
              })}
            </TabPanel>
          ))}
        </Tabs>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJsonLd) }}
      />
    </div>
  );
}
