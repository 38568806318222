'use client';
import { useLocalization } from '@akinon/next/hooks';
import { countries } from '@theme/countries';
import { Input } from './input';
import { Children, forwardRef } from 'react';
import clsx from 'clsx';
import { TURKEY_COUNTRY_KEY } from '@theme/constants';

export const PhoneInput = forwardRef((props: any, ref) => {
  const { t, locale } = useLocalization();
  const [_, currentCountryValue] = locale.split('-');

  const currentCountry = countries.find(
    (country) => country.value === currentCountryValue
  );
  const countryCode = props?.countryCode
    ? props?.countryCode
    : currentCountry?.areaCode;
  const phoneLength =
    props?.registeredCountry?.phoneLength ?? currentCountry?.phoneLength;

  const inputProps = {
    ...props,
    ref,
    format: currentCountry?.phoneFormat ?? `#`.repeat(phoneLength),
    label: null,
    inputClassName: clsx(props?.inputClassName, 'w-full'),
    icon: null,
    maxLength: currentCountry?.value === TURKEY_COUNTRY_KEY ? undefined : 13
  };

  return (
    <div>
      <label className="mb-1.5 block text-sm">
        {t('auth.register.form.phone.title')}
      </label>
      <div className="flex w-full items-start gap-2">
        <div className="flex h-10 w-14 shrink-0 items-center justify-center border border-lightBlue-600 bg-white text-sm">
          {countryCode ? `+${countryCode}` : ''}
        </div>
        <Input {...inputProps} />
      </div>
    </div>
  );
});

PhoneInput.displayName = 'PhoneInput';
