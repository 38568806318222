'use client';
import { SwiperNavigation, SwiperReact, SwiperSlide } from '@theme/components';
import { TitleWithSliderType } from './type';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Swiper from 'swiper';
import { Image } from '@akinon/next/components/image';

type SwiperStateType = {
  isEnd?: boolean;
  isBeginning?: boolean;
};

export default function DesriptionWithSlider({
  data
}: {
  data: TitleWithSliderType;
}) {
  const attributes = data?.attributes;
  const items = attributes?.slider;

  const swiperRef = useRef(null);

  const [, setSwiperState] = useState<SwiperStateType>({
    isBeginning: true
  });
  if (Object.keys(attributes || {}).length === 0) return null;

  return (
    <div className="border-b border-lightBlue">
      <div className="container">
        <div className=" py-4 lg:py-6">
          {attributes?.main?.value?.title && (
            <div className="text-center text-xl leading-[1.5] text-primary lg:text-[32px] lg:leading-[1.38]">
              {attributes?.main?.value?.title}
            </div>
          )}
          {items && (
            <SwiperReact
              ref={swiperRef}
              modules={[SwiperNavigation]}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              onSlideChange={({ isEnd, isBeginning }: Swiper) => {
                setSwiperState({ isEnd, isBeginning });
              }}
              className={clsx(
                'mt-4 !overflow-visible',
                'lg:mt-10 lg:!overflow-hidden'
              )}
              spaceBetween={13}
              slidesPerView="auto"
              breakpoints={{
                1024: {
                  spaceBetween: 24
                }
              }}
            >
              {items?.map((item, index) => {
                return (
                  <SwiperSlide
                    className="!w-fit"
                    key={`carousel-content-slide-${index}`}
                  >
                    <div className="relative">
                      <Image
                        imageClassName="w-[160px] object-cover lg:hidden block"
                        alt={item?.value?.alt}
                        src={item?.kwargs?.value?.mobile_image?.url}
                        width={160}
                        height={250}
                        aspectRatio={160 / 112}
                      />
                      <Image
                        imageClassName="w-[306px] object-cover lg:block hidden"
                        alt={item?.value?.alt}
                        src={item?.kwargs?.value?.image?.url}
                        width={306}
                        height={208}
                        aspectRatio={306 / 208}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
              <div
                className={clsx(
                  `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                  `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                  'lg:bg-primary lg:after:text-white'
                )}
              ></div>
              <div
                className={clsx(
                  `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
                  `after:!text-[.75rem] after:text-primary after:content-['next']`,
                  'lg:bg-primary lg:after:text-white'
                )}
              ></div>
            </SwiperReact>
          )}
        </div>
      </div>
    </div>
  );
}
