'use client';

import { useSendContactMutation } from '@akinon/next/data/client/account';
import { useLocalization } from '@akinon/next/hooks';
import { ContactFormType } from '@akinon/next/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Modal, Radio } from '@theme/components';
import { PhoneInput } from '@theme/components/phone-input';
import TextArea from '@theme/components/text-area';
import { countries } from '@theme/countries';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const contactFormSchema = (t, { phoneNumberLength }) =>
  yup.object().shape({
    full_name: yup.string().required(t('account.contact.form.error.required')),
    email: yup
      .string()
      .email(t('account.contact.form.error.email_valid'))
      .required(t('account.contact.form.error.required')),
    phone: yup
      .string()
      .transform((value) => value.replace(/[^0-9]/g, ''))
      .length(phoneNumberLength, t('account.contact.form.error.phone_length'))
      .required(t('account.contact.form.error.required')),
    subject: yup.string().required(t('account.contact.form.error.required')),
    message: yup
      .string()
      .required(t('account.contact.form.error.required'))
      .min(10, t('account.contact.form.error.message_length'))
      .max(280, t('account.contact.form.error.message_length'))
      .label('message')
  });

interface FaqContactFormType extends ContactFormType {
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

export default function FaqContactFormWidget() {
  const { data: session } = useSession();
  const { t, locale } = useLocalization();
  const [_, currentCountryValue] = locale.split('-');

  const currentCountry = countries.find(
    (country) => country.value === currentCountryValue
  );

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid }
  } = useForm<FaqContactFormType>({
    resolver: yupResolver(
      contactFormSchema(t, { phoneNumberLength: currentCountry?.phoneLength })
    )
  });

  const [sendContact, { isSuccess: formSuccess }] = useSendContactMutation();
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const onSubmit: SubmitHandler<FaqContactFormType> = (data) => {
    const formData = new FormData();
    formData.append('full_name', data.full_name);
    formData.append('email', data.email);
    formData.append('phone', `${currentCountry.areaCode}${data.phone}`);
    formData.append('message', data.message);
    formData.append('subject', data.subject);

    sendContact(formData)
      .then(() => {
        reset();
        setSuccessModalOpen(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    reset({
      full_name:
        session?.user?.firstName && session?.user?.lastName
          ? `${session?.user?.firstName} ${session?.user?.lastName}`
          : '',
      email: session?.user?.email,
      phone: session?.user?.phone,
      subject: '2'
    });
  }, [session, reset]);

  return (
    <div className="border-b border-t border-lightBlue-500">
      {formSuccess && (
        <Modal
          portalId="form-success-modal"
          open={isSuccessModalOpen}
          title={t('account.contact.form.success.title')}
          setOpen={(state) => {
            setSuccessModalOpen(state);
          }}
          titleClassName="text-2xl font-medium leading-8"
          className="bottom-0 top-auto w-full max-w-lg translate-y-0 p-6 md:bottom-auto md:top-1/2 md:-translate-y-1/2"
          classNameHeader="p-0 pb-4 border-b-0"
        >
          <div className="max-h-[85vh] overflow-y-auto pb-8">
            <div>{t('account.contact.form.success.description')}</div>
          </div>
          <Button
            onClick={() => setSuccessModalOpen(false)}
            className="h-12 w-full px-4 py-3 font-medium"
          >
            {t('account.contact.form.success.button')}
          </Button>
        </Modal>
      )}
      <div
        className={clsx(
          'container flex flex-col items-center  gap-8 py-10',
          'lg:flex-row lg:gap-10 lg:py-[72px]'
        )}
      >
        <div className="px-4 lg:max-w-[526px] lg:px-0">
          <h4
            className={clsx(
              'mb-2 text-3xl ',
              'lg:mb-4 lg:text-[40px] lg:leading-[1.3] lg:tracking-[-0.4px]'
            )}
          >
            {t('account.faq.form.content.title')}
          </h4>
          <p className="mb-4 text-base lg:mb-8 lg:text-xl">
            {t('account.faq.form.content.description')}
          </p>
          <Button>{t('account.faq.form.content.button')}</Button>
        </div>
        <div className="w-full px-4 lg:px-0">
          <h4 className={clsx('mb-4 text-xl font-medium', 'lg:mb-6')}>
            {t('account.faq.form.title')}
          </h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-8 flex gap-6">
              <Radio
                className="cursor-pointer"
                inputWidth={16}
                inputHeight={16}
                inputClassName="checked:border-[5px] bg-white"
                value={2}
                {...register('subject')}
              >
                <span className="text-sm font-medium">
                  {t('account.faq.form.subject.request')}
                </span>
              </Radio>

              <Radio
                className="cursor-pointer"
                inputWidth={16}
                inputHeight={16}
                inputClassName="checked:border-[5px] bg-white"
                value={3}
                {...register('subject')}
              >
                <span className="text-sm font-medium">
                  {t('account.faq.form.subject.recommendation')}
                </span>
              </Radio>

              <Radio
                className="cursor-pointer"
                inputWidth={16}
                inputHeight={16}
                inputClassName="checked:border-[5px] bg-white"
                value={4}
                {...register('subject')}
              >
                <span className="text-sm font-medium">
                  {t('account.faq.form.subject.complaint')}
                </span>
              </Radio>
            </div>
            <Input
              wrapperClassName="mb-4 w-full lg:mb-6"
              labelClassName="text-gray-800 font-medium !mb-1.5"
              className=" border-lightBlue-600 px-4 text-base lg:text-base"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {...register('full_name')}
              error={errors.full_name}
              label={t('account.faq.form.full_name.label')}
              required
            />
            <div
              className={clsx('flex flex-col gap-0', 'lg:flex-row lg:gap-4')}
            >
              <Input
                icon="pz-icon-mail"
                required
                label={t('account.faq.form.email.label')}
                labelClassName="text-gray-800 font-medium !mb-1.5"
                wrapperClassName="mb-4 w-full lg:mb-6"
                className=" border-lightBlue-600 px-4 pl-12 text-base lg:text-base"
                {...register('email')}
                error={errors.email}
              />

              <PhoneInput
                labelClassName="text-gray-800 font-medium !mb-1.5"
                className=" border-lightBlue-600 px-4 text-base lg:text-base"
                label={t('account.faq.form.phone.label')}
                mask="_"
                required
                wrapperClassName="mb-4 w-full lg:mb-6"
                format="+90 (5##) ### ## ##"
                allowEmptyFormatting={true}
                control={control}
                {...register('phone')}
                error={errors.phone}
                data-testid="phone"
              />
            </div>
            <div className="relative mb-6 leading-[0] lg:mb-8">
              <TextArea
                onChangeCallback={(value: string) => {
                  setValue('message', value, { shouldValidate: true });
                }}
                requiredClassName="text-gray-800"
                labelClassName="mb-1.5 block text-sm font-medium leading-normal text-gray-800 lg:leading-tight"
                {...register('message')}
                label={t('account.faq.form.message.label')}
                className={clsx(
                  'h-[154px] w-full resize-none border border-lightBlue-600 px-[14px] py-2 text-base font-normal lg:text-base',
                  'placeholder:text-sm placeholder:font-normal placeholder:text-gray-850 hover:border-black focus:border-black focus-visible:outline-none'
                )}
                counterClassName=" text-sm font-normal text-gray-850"
                required
                maxLength={280}
                characterCount
                error={errors.message}
                rows={6}
                placeholder={t('account.faq.form.message.placeholder')}
              />
            </div>

            <Button
              disabled={isValid}
              type="submit"
              className={clsx(
                'w-full  lg:w-auto lg:min-w-[120px]',
                'disabled:border-gray-500 disabled:bg-gray-500 disabled:text-gray-700'
              )}
            >
              {t('account.faq.form.button.label')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
