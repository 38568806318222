/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import { Image } from '@akinon/next/components/image';
import { Button, Icon, Link, Price } from '@theme/components';
import { useAddProductToBasket } from '@theme/hooks';
import { SwiperReact, SwiperSlide, SwiperNavigation } from '@theme/components';
import { ProductItem } from '@theme/views/product-item';
import clsx from 'clsx';

export default function BannerRecommendationContent({ data }) {
  const [addProduct, { isLoading }] = useAddProductToBasket();

  if (Object.keys(data || {}).length === 0) return null;

  const addProductToCart = (productPk, productSku) => {
    addProduct({
      product: productPk,
      quantity: 1,
      attributes: {},
      sku: productSku
    });
  };

  return (
    <div className=" border-b border-lightBlue py-[40px] md:py-[72px]">
      <div className="container px-4 ">
        <div
          {...(data?.attributes?.recommendation_banner?.value
            ?.widget_title_color && {
            style: {
              color:
                data?.attributes?.recommendation_banner?.value
                  ?.widget_title_color
            }
          })}
          className="px-5 text-center text-2xl leading-[32px] tracking-[-0.4px] lg:px-40 lg:text-[36px] lg:leading-[52px]"
        >
          {data?.attributes?.recommendation_banner?.value?.widget_title}
        </div>
        <Link
          className="mb-6 mt-4 flex items-center justify-center gap-1 text-sm md:text-base lg:mb-14"
          href={data?.attributes?.recommendation_banner?.value?.url || '#'}
        >
          <div
            {...(data?.attributes?.recommendation_banner?.value
              ?.url_text_color && {
              style: {
                color:
                  data?.attributes?.recommendation_banner?.value?.url_text_color
              }
            })}
            className="border-b border-primary pb-0.5 text-sm lg:text-base"
          >
            {data?.attributes?.recommendation_banner?.value?.url_text}
          </div>
          <Icon name={'arrow-right'} />
        </Link>
        <div>
          <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
            <div className="flex h-[235px] w-full lg:h-auto lg:w-1/4">
              <Link
                className="relative h-full w-full"
                href={
                  data?.attributes?.recommendation_banner?.value?.url || '#'
                }
              >
                <Image
                  className="hidden h-full w-full object-cover object-center lg:block xl:h-[464px]"
                  imageClassName="object-center"
                  aspectRatio={310 / 464}
                  sizes="306px"
                  fill
                  src={
                    data?.attributes?.recommendation_banner?.kwargs?.value
                      ?.image?.url ?? '/noimage.jpg'
                  }
                  alt={
                    data?.attributes?.recommendation_banner?.value?.image_alt
                  }
                />
                <Image
                  className="block h-full w-full object-cover object-center lg:hidden"
                  imageClassName="object-center"
                  aspectRatio={343 / 235}
                  sizes="343px"
                  fill
                  src={
                    data?.attributes?.recommendation_banner?.kwargs?.value
                      ?.mobile_image?.url ?? '/noimage.jpg'
                  }
                  alt={
                    data?.attributes?.recommendation_banner?.value?.image_alt
                  }
                />

                <div
                  {...(data?.attributes?.recommendation_banner?.value
                    ?.banner_title_color && {
                    style: {
                      color:
                        data?.attributes?.recommendation_banner?.value
                          ?.banner_title_color
                    }
                  })}
                  className="absolute left-4 top-3 font-bold md:left-6 md:top-6 md:text-xl"
                >
                  {data?.attributes?.recommendation_banner?.value?.banner_title}
                </div>
                <Button
                  className="absolute left-4 top-[40px] px-3 md:left-6 md:top-[76px] md:px-4"
                  appearance="filled"
                >
                  {data?.attributes?.recommendation_banner?.value?.button}
                </Button>
              </Link>
            </div>
            <div className="w-full overflow-hidden lg:w-3/4">
              <SwiperReact
                slidesPerView={2}
                className="w-full"
                spaceBetween={10}
                modules={[SwiperNavigation]}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                }}
                onSlideChange={(swiper) => {
                  if (swiper.realIndex === 0) return;
                  document
                    .querySelector('.swiper-button-prev')
                    .classList.remove('!hidden');
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 3.2,
                    spaceBetween: 13.3
                  },
                  1024: {
                    slidesPerView: 3.2,
                    spaceBetween: 36
                  },
                  1440: {
                    slidesPerView: 4,
                    spaceBetween: 24
                  }
                }}
              >
                {data?.products?.map((product, index) => (
                  <SwiperSlide key={product.pk}>
                    <ProductItem
                      product={product}
                      width={306}
                      height={450}
                      index={index}
                    />
                  </SwiperSlide>
                ))}
                <div
                  className={clsx(
                    `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-400 text-primary`,
                    `after:!text-[.75rem] after:text-primary after:content-['prev']`,
                    'lg:bg-primary lg:after:text-white'
                  )}
                ></div>
                <div
                  className={clsx(
                    `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-400 text-primary`,
                    `after:!text-[.75rem] after:text-primary after:content-['next']`,
                    'lg:bg-primary lg:after:text-white'
                  )}
                ></div>
              </SwiperReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
