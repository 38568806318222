'use client';

import { Button, Icon, CustomSelect, Radio } from '@theme/components';
import React from 'react';
import { RetailCities, StoreResult } from './type';
import { useLocalization } from '@akinon/next/hooks';
import clsx from 'clsx';

interface Store {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: StoreResult[];
}

interface StoresListProps {
  stores: Store;
  cities: RetailCities;
  selectedCityPk: string | null;
  setSelectedCityPk: React.Dispatch<React.SetStateAction<string | null>>;
  selectedStorePk: string | null;
  setSelectedStorePk: React.Dispatch<React.SetStateAction<string | null>>;
  userLocation: {
    lat: number | null;
    lng: number | null;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserLocation: React.Dispatch<React.SetStateAction<any>>;
}

export default function StoresList({
  stores,
  cities,
  selectedCityPk,
  setSelectedCityPk,
  selectedStorePk,
  setSelectedStorePk,
}: StoresListProps) {
  const { t } = useLocalization();
  const cityOptions = [
    { label: t('store.store.find_store_placeholder'), value: '00' },
    ...cities.results.map((city) => ({
      label: city.name,
      value: city.pk
    }))
  ];
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [selectedPk, setSelectedPk] = React.useState<string | null>(null);

  const getStoresByCity = (cityPk: string) => {
    if (cityPk === '00') {
      return stores.results;
    } else {
      return stores.results.filter(
        (store) => store.township.city.pk === parseInt(cityPk)
      );
    }
  };

  return (
    <div className="h-full md:max-h-[640px]">
      <>
        <div className="flex justify-between pb-[6px] text-sm text-gray">
          <span>{t('store.store.filter.find_store')}</span>
          <span className="hidden md:block">
            {getStoresByCity(selectedCityPk).length}{' '}
            {t('store.store.found_stores')}
          </span>
        </div>

        <CustomSelect
          options={cityOptions}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectedCityPk(e.target.value);
          }}
          value={selectedCityPk || ''}
          className="w-100 hidden w-full cursor-pointer truncate border-b border-gray-100 bg-gray-25 py-1 text-opacity-65 outline-none md:flex"
        />
        <Button
          appearance="outlined"
          className="mt-0 flex w-full items-center justify-between text-sm text-primary md:mt-4 md:hidden"
          value={selectedCityPk}
          onClick={() => setIsMenuOpen(true)}
        >
          {
            cities.results.filter(
              (city) => city.pk.toString() === selectedCityPk
            )[0]?.name
          }
          {!cities.results.filter(
            (city) => city.pk.toString() === selectedPk
          )[0]?.name && cityOptions[0].label}
          <Icon name="chevron-end" size={12} className="ml-2" />
        </Button>
        <div className="pt-4 text-sm text-gray md:hidden">
          {getStoresByCity(selectedCityPk).length}{' '}
          {t('store.store.found_stores')}
        </div>
        <div
          className={clsx(
            'fixed bottom-0 left-0 top-0 z-50 w-full bg-white transition-all duration-300 ease-in',
            isMenuOpen
              ? 'flex flex-col overflow-auto opacity-100'
              : 'invisible absolute -translate-x-full opacity-0'
          )}
        >
          <div className="flex items-center justify-start border-b border-lightBlue-500 px-6 py-[22px]">
            <Icon
              name="arrow-left"
              size={32}
              onClick={() => setIsMenuOpen(false)}
            />
            <span className="pl-2 text-sm">
              {' '}
              {t('store.store.filter.find_store')}{' '}
            </span>
          </div>
          <div className="px-6 pb-6 pt-4 text-sm">
            {t('store.store.filter.find_store_desc')}
          </div>
          <div className="px-6 pb-16">
            {cityOptions.map((city) => (
              <Radio
                key={city.value}
                value={city.value}
                checked={city.value.toString() === selectedPk}
                onChange={() => setSelectedPk(city.value.toString())}
                spanClassName="py-3"
              >
                {city.label}
              </Radio>
            ))}
          </div>
          <div className="fixed bottom-0 w-full border-t border-lightBlue-500 bg-white p-4">
            <Button
              onClick={() => {
                setSelectedCityPk(selectedPk), setIsMenuOpen(false);
              }}
              className={clsx(
                'w-full',
                !selectedPk
                  ? 'pointer-events-none cursor-not-allowed border-gray-500 bg-gray-500'
                  : 'cursor-pointer bg-black'
              )}
            >
              Seç
            </Button>
          </div>
        </div>
        {selectedCityPk && (
          <div className="mt-2 flex h-full flex-col gap-4 overflow-y-auto md:mt-4 md:max-h-[540px]">
            {getStoresByCity(selectedCityPk)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((store) => (
                <div
                  key={store.pk}
                  className={
                    store.pk.toString() === selectedStorePk
                      ? 'border border-black'
                      : ''
                  }
                >
                  <Radio
                    onChange={() => setSelectedStorePk(store.pk.toString())}
                    value={store.pk}
                    checked={store.pk.toString() === selectedStorePk}
                    className="relative border border-lightBlue"
                    inputClassName="absolute left-4 top-4"
                    spanClassName="ml-12 mr-4 w-full my-4"
                  >
                    <div className="pb-1 text-xs text-gray-700">
                      {store.township.city.name}
                    </div>
                    <div className="pb-1 text-lg font-medium text-primary">
                      {store.name}
                    </div>
                    <div className="pb-4">{store.address}</div>
                    {store.store_hours[0] && (
                      <div className="flex items-center pb-2">
                        <Icon
                          name="clock"
                          size={20}
                          className="mr-2 text-secondary"
                        />

                        <div>
                          {t('store.store.working_hours')}:{' '}
                          {store.store_hours[0]?.[0].slice(0, -3)} -{' '}
                          {store.store_hours[0]?.[1].slice(0, -3)}
                        </div>
                      </div>
                    )}

                    <div className="flex items-center pb-5">
                      <Icon
                        name="phone"
                        size={20}
                        className="mr-2 text-secondary"
                      />
                      <span>
                        {store.phone_number ? store.phone_number : ''}
                      </span>
                    </div>

                    <div> {t('store.store.open')}</div>
                  </Radio>
                </div>
              ))}
          </div>
        )}
      </>
    </div>
  );
}
