import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  step: number;
  stepCount: number;
  answers: {
    firstQuestion: string | null;
    secondQuestion: string | null;
    thirdQuestion: string | null;
  };
  completed: boolean;
};

const InitialState: TInitialState = {
  step: 1,
  stepCount: 3,
  answers: {
    firstQuestion: null,
    secondQuestion: null,
    thirdQuestion: null,
  },
  completed: false,
};

const giftWizardSlice = createSlice({
  name: 'giftWizard',
  initialState: InitialState,
  reducers: {
    setAnswer: (state, action) => {
      const { question, answer } = action.payload;
      state.answers[question] = answer;

      const allQuestionsAnswered = Object.values(state.answers).every((ans) => ans !== null);
      if (allQuestionsAnswered && state.step === state.stepCount) {
        state.completed = true;
      }
    },
    nextStep: (state) => {
      if (state.step < state.stepCount) {
        state.step += 1;
      }
    },
    prevStep: (state) => {
      if (state.step > 1) {
        state.step -= 1;
      }
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    resetWizard: (state) => {
      state.step = 1;
      state.answers = {
        firstQuestion: null,
        secondQuestion: null,
        thirdQuestion: null,
      };
      state.completed = false;
    },
    checkAnswers: (state) => {
      state.completed = false;
      state.step = 1;
    }
  },
});

export const { setAnswer, nextStep, prevStep, setStep, resetWizard, checkAnswers } = giftWizardSlice.actions;
export default giftWizardSlice.reducer;