import React from 'react';
import { SwiperReact, SwiperSlide } from '@theme/components';
import { useMediaQuery } from '@akinon/next/hooks';
import { ProductItem } from '@theme/views/product-item';
import { Navigation } from 'swiper/modules';
import clsx from 'clsx';

export default function VerticalProductContent({ data }) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');

  const breakpoints = {
    0: {
      slidesPerView: 1.8,
      spaceBetween: 24
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    1440: {
      slidesPerView: 2,
      spaceBetween: 0
    }
  };

  return (
    <div className="w-full relative">
      <SwiperReact
        breakpoints={breakpoints}
        direction={!isDesktop ? 'horizontal' : 'vertical'}
        allowTouchMove={isMobile}
        modules={[Navigation]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        className={`w-full lg:min-h-[576px] lg:max-h-[576px] ${isMobile ? 'swiper-has-navigation' : ''}`}
      >
        {data?.map((product, index) => (
          <SwiperSlide 
            key={product.id}
            className={`
             w-full xl:max-w-[296px]
              ${!isMobile && index === 0 ? 'mb-6' : ''}
              xl:max-h-[276px]
            `}
          >
            <ProductItem
              product={product}
              width={isMobile ? 1200 : 1500}
              contentClassName="!px-0"
              index={index}
              height={isMobile ? 1920 : 1920}
              className="transition-all duration-300 max-w-[179px] md:max-w-full xl:max-w-[296px]"
              showBadges={false}
              isVertical={!isMobile}
            />
          </SwiperSlide>
        ))}
          <>
          <div
            className={clsx(
              `swiper-button-prev swiper-button-prev::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['prev']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
          <div
            className={clsx(
              `swiper-button-next swiper-button-next::after !mr-[.5625rem] !h-[2rem] !w-[2rem] rounded-full bg-gray-100 text-primary`,
              `after:!text-[.75rem] after:text-primary after:content-['next']`,
              'lg:bg-primary lg:after:text-white'
            )}
          ></div>
          </>
      </SwiperReact>
    </div>
  );
}
