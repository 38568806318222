import React, { useState, useRef } from 'react';
import { Icon, Link } from '@theme/components';

interface VideoProps {
  src: string;
  url?: string;
  className?: string;
}

export const Video = ({ src, className }: VideoProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="relative cursor-pointer" onClick={handleVideoClick}>
      <video ref={videoRef} muted loop playsInline className={className}>
        <source src={src} type="video/mp4" />
      </video>
      {!isPlaying && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
            <Icon name="play" size={40} className='text-white' />
          </div>
        </div>
      )}
    </div>
  );
};
