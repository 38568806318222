'use client';
import { Link } from '@theme/components/link';
import { WidgetData } from './type';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { useLocalization } from '@akinon/next/hooks';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import { Skeleton, SkeletonWrapper } from '@theme/components';

const ContactUsInformations = () => {
  const { data, isLoading } = useGetWidgetQuery<WidgetData>(
    'contact-informations'
  );

  const attributes = data?.attributes;

  const { t } = useLocalization();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBmKyHOy4dPb2SdGaCYLtiE0EjCpcmBQ6g'
  });

  const [, setMap] = useState(null);

  const onLoad = useCallback((map) => setMap(map), []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  if (isLoading) {
    return (
      <SkeletonWrapper className="p-10">
        <Skeleton className="h-[400px] w-full" />
      </SkeletonWrapper>
    );
  }

  if (Object.keys(attributes || {}).length === 0) return null;
  return (
    <section className="container">
      <div className="mb-6 mt-[-20px] md:mb-16">

        <p className="text-sm">{t('common.contact_us.description')}</p>
      </div>
      <div className="mb-10 flex flex-col gap-6 md:mb-[72px] md:flex-row md:justify-between">
        <div>
          <p className="mb-3 text-lg font-medium leading-[30px] md:text-[1.75rem] md:leading-9">
            {t('common.contact_us.headquarters')}
          </p>
          <p className="mb-2 text-base font-semibold md:text-xl md:leading-[30px]">
            Paşabahçe Mağazaları A.Ş
          </p>
          <address className="mb-10 text-sm not-italic md:max-w-[21rem] md:text-base">
            {data.attributes?.headquarters_address?.value?.address}
          </address>
          <p className="mb-3 text-lg font-medium leading-[30px] md:text-[1.75rem] md:leading-9">
            {t('common.contact_us.customer_services')}
          </p>
          <p className="mb-2 text-sm md:text-base">
            <b>Tel: </b>
            <Link href="tel:+9008502221935">
              {data.attributes?.customer_services?.value?.telephone}
            </Link>
          </p>
          <p className="mb-2 text-sm md:text-base">
            <b>{t('common.contact_us.email')}: </b>
            <Link href="mailto:musterihizmetleri@pasabahce.com">
              {data.attributes?.customer_services?.value?.email}
            </Link>
          </p>
          <p className="mb-6 text-sm md:text-base">
            <b>{t('common.contact_us.mersis_number')}: </b>
            {data.attributes?.customer_services?.value?.mersis_number}
          </p>
          <p className="mb-3 text-base font-semibold md:text-xl md:leading-[30px]">
            {t('common.contact_us.working_hours')}
          </p>
          <p>{data.attributes?.working_hours?.value?.text}</p>
        </div>
        <div className="min-h-[208px] w-full md:h-[396px] md:w-[676px]"></div>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              height: '100%',
              width: '100%',
              aspectRatio: '343/208'
            }}
            center={{ lat: 40.84098603804983, lng: 29.310082754619835 }}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              position={{ lat: 40.84098603804983, lng: 29.310082754619835 }}
            />
          </GoogleMap>
        ) : (
          <SkeletonWrapper>
            <Skeleton className="h-full w-[400px]" />
          </SkeletonWrapper>
        )}
      </div>
    </section>
  );
};

export default ContactUsInformations;
