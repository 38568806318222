'use client';

import { useDispatch, useSelector } from 'react-redux';
import { setStep } from '@theme/redux/reducers/giftwizard';
import type { RootState } from '@theme/redux/store';

export default function StepProgress() {
  const { step, stepCount } = useSelector((state: RootState) => state.giftWizard);
  const dispatch = useDispatch();

  const handleStepClick = (stepNumber: number) => {
    if (stepNumber <= step) {
      dispatch(setStep(stepNumber));
    }
  };

  return (
    <div className="flex items-center">
      {Array.from({ length: stepCount }).map((_, index) => {
        const stepNumber = index + 1;
        const isActive = stepNumber === step;
        const isCompleted = stepNumber < step;

        return (
          <div key={stepNumber} className="flex items-center">
            <div
              onClick={() => handleStepClick(stepNumber)}
              className={`
                flex font-bold items-center justify-center cursor-pointer w-8 h-8 rounded-full border-2
                ${isActive ? 'border-primary text-primary' : ''} 
                ${isCompleted ? 'bg-primary text-white border-primary' : 'border-lightBlue-500 text-gray-700'}
              `}
            >
              {stepNumber}
            </div>
            {stepNumber !== stepCount && (
              <div
                className={`h-1 w-8 border-t-2 ${
                  isCompleted ? 'border-black' : 'border-gray-300'
                } border-dashed`}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
}
