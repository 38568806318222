'use client';

import { useSelector } from 'react-redux';
import { GiftWizardType } from './type';
import Question from './question';
import type { RootState } from '@theme/redux/store';
import { useEffect } from 'react';
import { useLocalization, useRouter } from '@akinon/next/hooks';
import { LoaderSpinner } from '@theme/components';
import StepProgress from './step-progress';

export default function GiftWizard({
  data
}: {
  data: GiftWizardType;
}) {
  const { t } = useLocalization();
  const {step, completed, answers} = useSelector((state: RootState) => state.giftWizard);
  const router = useRouter();

  useEffect(() => {
    if (completed) {
      const categoryKey = Object.values(answers).join('-');
      const redirectPath = `/${categoryKey}?giftwizard`;
      router.push(redirectPath);
    }
  }, [completed, answers, router]);

  return (
    <div>
      <div className='container'>
        <div className='flex flex-col lg:flex-row w-full justify-start items-baseline lg:justify-between lg:items-center gap-y-4 mb-10 lg:mb-12'>
          <h1 className='text-2xl  lg:text-[40px] lg:leading-[52px] lg:tracking-[-0.4px] text-primary'>{t('category.giftwizard.flatpage_title')}</h1>
          <div className='relative'>
            <StepProgress />
          </div>
        </div>
      </div>
      {step === 1 && (
        <Question
          questions={data?.attributes?.first_questions}
          title={data?.attributes?.first_question_title}
          questionKey={'firstQuestion'}
          selectedAnswer={answers.firstQuestion}
        />
      )}
      {step === 2 && (
        <Question
          questions={data?.attributes?.second_questions}
          title={data?.attributes?.second_question_title}
          questionKey={'secondQuestion'}
          selectedAnswer={answers.secondQuestion}
        />
      )}
      {step === 3 && (
        <Question
          questions={data?.attributes?.third_questions}
          title={data?.attributes?.third_question_title}
          questionKey={'thirdQuestion'}
          selectedAnswer={answers.thirdQuestion}
        />
      )}
      {completed && (
        <div className='fixed top-0 left-0 z-50 flex justify-center items-center w-[100vw] h-[100vh] bg-black bg-opacity-5'>
          <LoaderSpinner className='my-4' />
        </div>
      )}
    </div>
  );
};
