'use client';

import { useDispatch } from 'react-redux';
import { Image } from '@akinon/next/components/image';
import { Radio } from '@theme/components';
import { setAnswer, nextStep } from '@theme/redux/reducers/giftwizard';
import type { QuestionProps } from './type';

export default function Question({questions, title, questionKey, selectedAnswer}: QuestionProps) {
  const dispatch = useDispatch();

  const handleOptionClick = (option) => {
    dispatch(setAnswer({ question: questionKey, answer: option }));
    dispatch(nextStep());
  };

  return (
    <div className='container'>
      <div className='text-center'>
        {title?.value && <h2 className='text-xl leading-[30px] mb-6 lg:mb-8 lg:text-[32px] lg:leading-[44px] text-primary'>{title?.value}</h2>}

        <div className='lg:flex justify-center items-center gap-x-2 lg:gap-x-6 flex-wrap grid grid-cols-3'>
          {questions?.map((question, index) => (
            <div key={index}>
              <Radio
                className="flex items-start pb-4 cursor-pointer relative"
                spanClassName='ml-0'
                inputClassName="absolute left-2 top-2 w-full h-full cursor-pointer z-10 bg-white"
                key={question?.value?.value}
                type="radio"
                name={question?.value?.value}
                value={question?.value?.value}
                checked={selectedAnswer === question?.value?.value}
                onChange={() => handleOptionClick(question?.value?.value)}
              >
                <Image
                  className="block pb-2"
                  imageClassName='max-w-[160px] max-h-[160px] w-full h-full object-cover'
                  width={160}
                  height={160}
                  aspectRatio={1}
                  src={question?.kwargs?.value?.image?.url || '/noimage.jpg'}
                  alt={question?.value?.label}
                />
                {question?.value?.label && <span className="text-gray-700 font-medium text-xs lg:text-base">{question?.value?.label} </span>}
              </Radio>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}