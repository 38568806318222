import React, { useState, ChangeEvent, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useLocalization } from '@akinon/next/hooks';

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLength?: number;
  characterCount?: boolean;
  counterClassName?: string;
  error?: FieldError | undefined;
  labelClassName?: string;
  label?: string;
  className?: string;
  required?: boolean;
  requiredClassName?: string;
  value?: string;
  errorClassName?: string;
  onChangeCallback?(value: string): void;
  emojiAllowed?: boolean;
  isRemainingTextFormat?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      maxLength,
      characterCount,
      error,
      labelClassName,
      className,
      required = false,
      requiredClassName,
      label,
      counterClassName,
      errorClassName,
      onChangeCallback,
      emojiAllowed = true,
      isRemainingTextFormat,
      ...rest
    },
    ref
  ) => {
    const [text, setText] = useState(rest?.value ?? '');
    const { t } = useLocalization();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      let value = e.target.value;
      if (!emojiAllowed) {
        value = value.replace(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF])/gu,
          ''
        );
      }
      setText(value);
      onChangeCallback?.(value);
    };

    return (
      <label
        className={twMerge('relative text-sm text-gray-800', labelClassName)}
      >
        {label}
        {required && (
          <span className={twMerge('text-secondary', requiredClassName)}>
            {' '}
            *
          </span>
        )}

        <textarea
          {...rest}
          ref={ref}
          maxLength={maxLength}
          value={text}
          onChange={handleChange}
          className={twMerge(
            'mt-2 block w-full border p-2 text-primary placeholder:text-gray-700',
            `${error ? 'border-error focus:border-error' : 'border-gray-500 hover:border-black focus:border-black'}`,
            className
          )}
        />
        {characterCount && maxLength && (
          <span
            className={twMerge(
              'absolute bottom-2 right-[14px] text-xs leading-[1.5] text-gray-850',
              counterClassName
            )}
          >
            {isRemainingTextFormat
              ? `${maxLength - text.length} ${t('basket.card.gift_package_note.info')}`
              : `${text.length}/${maxLength}`
            }
          </span>
        )}
        {error && (
          <span
            className={twMerge(
              'absolute -bottom-5 left-0 text-sm text-error',
              errorClassName
            )}
          >
            {error.message}
          </span>
        )}
      </label>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
