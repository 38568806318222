'use client';
import { SwiperNavigation, SwiperReact, SwiperSlide } from '@theme/components';
import { DescriptionWithSliderType } from './type';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Swiper from 'swiper';
import { Image } from '@akinon/next/components/image';
import { useMediaQuery } from '@akinon/next/hooks';

type SwiperStateType = {
  isEnd?: boolean;
  isBeginning?: boolean;
};

export default function DesriptionWithSlider({
  data
}: {
  data: DescriptionWithSliderType;
}) {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const attributes = data?.attributes;
  const items = attributes?.catalogs;

  const swiperRef = useRef(null);

  const [, setSwiperState] = useState<SwiperStateType>({
    isBeginning: true
  });
  if (Object.keys(attributes || {}).length === 0) return null;

  return (
    <div className="border-b border-lightBlue">
      <div className="container ">
        <div className="mb-10 bg-secondary-50 p-4 lg:p-6">
          {attributes?.main?.value?.content && (
            <div
              className="text-sm leading-[1.43] text-primary lg:text-lg lg:leading-6"
              dangerouslySetInnerHTML={{
                __html: attributes?.main?.value?.content
              }}
            ></div>
          )}
          {items && (
            <SwiperReact
              ref={swiperRef}
              onSlideChange={({ isEnd, isBeginning }: Swiper) => {
                setSwiperState({ isEnd, isBeginning });
              }}
              modules={[SwiperNavigation]}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              className={clsx('mt-6')}
              spaceBetween={24}
              slidesPerView="auto"
            >
              {items?.length > 0 &&
                items?.map((item, index) => {
                  const title = item.value?.title;
                  const image = item?.kwargs?.value?.image?.url;
                  const mobile_image = item?.kwargs?.value?.mobile_image?.url;
                  return (
                    <SwiperSlide
                      className="!w-fit"
                      key={`carousel-content-slide-${index}`}
                    >
                      <div className="relative">
                        <Image
                          imageClassName="w-[200px] lg:w-[250px] object-cover"
                          alt={item?.value?.alt}
                          src={isMobile ? image : mobile_image}
                          width={isMobile ? 200 : 250}
                          height={isMobile ? 200 : 250}
                          aspectRatio={250 / 250}
                        />
                        {title && (
                          <h3
                            className={clsx(
                              ' text-xl font-medium leading-6 text-secondary',
                              'lg:mt-[5px]'
                            )}
                          >
                            {title}
                          </h3>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </SwiperReact>
          )}
        </div>
      </div>
    </div>
  );
}
