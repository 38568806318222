'use client';
import { Button } from '@theme/components';
import { ClubCardBannerType } from './type';
import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { ROUTES } from '@theme/routes';
import { useGetProfileInfoQuery } from '@akinon/next/data/client/account';
import { useSession } from 'next-auth/react';

export default function ClubCardBanner({ data }: { data: ClubCardBannerType }) {
  const { attributes } = data;

  const { status: sessionStatus } = useSession();
  if (Object.keys(data || {}).length === 0) return;

  const scrollToAccordion = (question) => {
    const element = document.getElementById(question);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      element.click();
    }
  };

  const firstLinkDisplay: 'show' | 'hide' = attributes?.club_card_banner?.value
    ?.show_first_linked_url as 'hide' | 'show';
  const secondLinkDisplay: 'show' | 'hide' = attributes?.club_card_banner?.value
    ?.show_second_linked_url as 'hide' | 'show';

  return (
    <div className="relative">
      <Image
        className="hidden md:block"
        imageClassName="h-auto w-full object-cover"
        alt={attributes?.club_card_banner?.value?.image_alt}
        src={
          data?.attributes.club_card_banner?.kwargs?.value?.image?.url ??
          '/noimage.jpg'
        }
        width={1440}
        height={544}
      />
      <Image
        className="block md:hidden"
        imageClassName="h-auto w-full object-cover"
        alt={attributes?.club_card_banner?.value?.image_alt}
        src={
          attributes?.club_card_banner?.kwargs?.value?.mobile_image?.url ??
          '/noimage.jpg'
        }
        width={343}
        height={524}
      />
      <div className="absolute bottom-4 flex w-full flex-col px-4 md:top-1/2 md:w-1/2 md:-translate-y-1/2 md:pl-[72px]">
        <div
          dangerouslySetInnerHTML={{
            __html: attributes?.club_card_banner?.value?.title
          }}
          className="pb-8 text-2xl leading-[54px] text-white md:text-[40px]"
        ></div>
        <div className="flex w-full gap-6">
          {sessionStatus === 'authenticated' && (
            <RegisterButton attributes={data?.attributes} />
          )}
          <div className="w-1/2 md:order-2">
            <Button
              appearance="ghost"
              onClick={() => scrollToAccordion('detailed-button-scroll')}
              className="w-full border-white text-white hover:bg-white hover:text-black focus:border-white focus:bg-transparent focus:text-white"
            >
              {attributes?.club_card_banner?.value?.second_button_label}
            </Button>
          </div>
        </div>
        <div className="flex w-full flex-col items-center gap-6 pt-4 md:flex-row">
          {sessionStatus === 'authenticated' && (
            <SubscriptionConfirm attributes={data?.attributes} />
          )}
          {firstLinkDisplay === 'hide' &&
          secondLinkDisplay === 'hide' ? null : (
            <div className="flex w-full gap-4 text-sm md:w-1/2">
              {firstLinkDisplay === 'show' &&
                attributes?.club_card_banner?.value?.first_linked_text && (
                  <Link
                    href={attributes?.club_card_banner?.value?.first_linked_url}
                    className="border-b text-white"
                  >
                    <span>
                      {attributes?.club_card_banner?.value?.first_linked_text}
                    </span>
                  </Link>
                )}

              {secondLinkDisplay === 'show' &&
                attributes?.club_card_banner?.value?.second_linked_text && (
                  <Link
                    href={
                      attributes?.club_card_banner?.value?.second_linked_url
                    }
                    className="border-b text-white"
                  >
                    <span>
                      {attributes?.club_card_banner?.value?.second_linked_text}
                    </span>
                  </Link>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SubscriptionConfirm = ({ attributes }) => {
  const { data: profileInfo } = useGetProfileInfoQuery();

  return (
    !profileInfo?.attributes?.subscription_confirm && (
      <div className="w-full text-sm text-success md:w-1/2 md:text-xl">
        {attributes?.club_card_banner?.value?.description}
      </div>
    )
  );
};

const RegisterButton = ({ attributes }) => {
  const { data: profileInfo } = useGetProfileInfoQuery();
  return (
    !profileInfo?.attributes?.subscription_confirm && (
      <Link className="order-1 w-1/2" href={ROUTES.REGISTER}>
        <Button appearance="outlined" className="w-full bg-white">
          {attributes?.club_card_banner?.value?.first_button_label}
        </Button>
      </Link>
    )
  );
};
