'use client';
import { Image } from '@akinon/next/components/image';
import {
  Link,
  SwiperNavigation,
  SwiperPagination,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import { pushSelectPromotion, pushViewPromotion } from '@theme/utils/gtm';
import clsx from 'clsx';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Autoplay } from 'swiper/modules';
const HomeHeroSliderItem = ({ item, index }) => {
  const { ref, inView } = useInView();
  const [viewed, setViewed] = useState(false);
  const isVideo = item?.value?.is_video_or_image === 'video';
  const promotionEvent = useMemo(
    () => ({
      creative_name: item?.kwargs?.value?.image?.url,
      creative_slot: `Home Hero Slider ${index + 1}`,
      promotion_id: `home-hero-slider_${index + 1}`,
      promotion_name: item?.value?.content
    }),
    [item, index]
  );
  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      pushViewPromotion(promotionEvent);
    }
  }, [inView, viewed, promotionEvent]);
  return (
    <>
      <Link
        className="block !h-[30.3125rem] overflow-clip md:!h-[33rem]"
        href={item?.value?.url}
        onClick={() => pushSelectPromotion(promotionEvent)}
      >
        <div
          className={clsx(
            'absolute top-6 z-10 flex  w-auto flex-col items-start justify-between px-4',
            'lg:top-[5.375rem] lg:flex-row lg:items-center lg:px-[4.5rem]',
            !item?.value?.content && '!top-[unset] bottom-[30%]'
          )}
          ref={ref}
        >
          <div className="flex flex-col items-start gap-4 lg:gap-8">
            {item?.value?.content && (
              <h1
                {...(item?.value?.banner_color && {
                  style: { color: item?.value?.banner_color }
                })}
                className="h-auto w-[10.625rem] text-[2rem] font-normal leading-[2.75rem] lg:w-[16.25rem] lg:text-[3rem] lg:leading-[3.75rem]"
              >
                {item?.value?.content}
              </h1>
            )}
            {item?.value?.button_text && (
              <Link
                href={item?.value?.button_url}
                className={clsx(
                  'flex h-10 items-center justify-center px-8 py-3 text-sm font-normal lg:h-12',
                  'border border-primary bg-primary text-primary-foreground transition-all',
                  'hover:border-primary hover:bg-white hover:text-black'
                )}
                onClick={() => pushSelectPromotion(promotionEvent)}
                style={{
                  backgroundColor:
                    item?.value?.button_color && item?.value?.button_color,
                  color:
                    item?.value?.button_text_color &&
                    item?.value?.button_text_color
                }}
              >
                {item?.value?.button_text}
              </Link>
            )}
          </div>
        </div>
        {isVideo ? (
          <>
            <span className="hidden md:block">
              <video
                className="min-h-full min-w-full"
                autoPlay
                src={item?.kwargs?.value?.video?.url}
                muted
                loop
                playsInline
              ></video>
            </span>
            <span className="block md:hidden">
              <video
                className="min-h-full min-w-full"
                autoPlay
                src={item?.kwargs?.value?.mobile_video?.url}
                muted
                loop
                playsInline
              ></video>
            </span>
          </>
        ) : (
          <>
            <Image
              loading={index == 0 ? 'eager' : 'lazy'}
              fetchPriority={index == 0 ? 'high' : 'low'}
              src={item?.kwargs?.value?.mobile_image?.url ?? '/noimage.jpg'}
              alt={item?.value?.alt}
              aspectRatio={440 / 400}
              sizes="440px"
              fill
              crop="none"
              className="block !h-full !w-full md:hidden"
            />
            <Image
              loading={index == 0 ? 'eager' : 'lazy'}
              fetchPriority={index == 0 ? 'high' : 'low'}
              src={item?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
              alt={item?.value?.alt}
              aspectRatio={1440 / 528}
              width={1440}
              height={528}
              sizes="1440px"
              imageClassName="w-full h-full"
              crop="none"
              className="hidden !h-full !w-full md:block"
            />
          </>
        )}
      </Link>
    </>
  );
};
export default function HomeHeroSliderContent({ data }) {
  const renderCustomBullet = (index, className) => {
    return `<span class="!w-[13px] !h-[13px] !rounded-full ${className}"></span>`;
  };

  const filteredData = data?.attributes?.home_hero_slider?.filter((item) => {
    const startDate = item?.value?.start_date ? new Date(item.value.start_date) : null;
    const endDate = item?.value?.end_date ? new Date(item.value.end_date) : null;
    const now = new Date();

    if (startDate && endDate) {
      return now >= startDate && now <= endDate;
    }

    return true;
  });

  return (
    <div className="relative mx-auto h-[30.3125rem] max-w-[1440px] overflow-hidden px-4 lg:h-[33rem] lg:px-0">
      <SwiperReact
        style={
          {
            position: 'relative',
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff'
          } as CSSProperties
        }
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          bulletClass: 'swiper-pagination-bullet home-bullets',
          bulletActiveClass:
            'swiper-pagination-bullet-active border border-black',
          renderBullet: renderCustomBullet
        }}
        modules={[SwiperPagination, SwiperNavigation, Autoplay]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {filteredData?.map((item, i) => (
          <SwiperSlide className="relative" key={i}>
            <HomeHeroSliderItem item={item} index={i} />
          </SwiperSlide>
        ))}
        {filteredData.length > 1 && (
          <div
            className={clsx(
              'swiper-pagination !bottom-6 z-10 !flex !w-full !flex-row !justify-center !gap-2',
              'lg:!right-[4.875rem] lg:!top-[11.25rem] lg:bottom-auto lg:h-min lg:!w-auto lg:!flex-col lg:!items-end lg:!justify-start'
            )}
          ></div>
        )}
      </SwiperReact>
    </div>
  );
}
