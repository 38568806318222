'use client';

import { Product } from '@akinon/next/types';
import { VerticalRecommendationBannerType } from './type';
import { useMediaQuery } from '@akinon/next/hooks';
import { Icon, Link } from '@theme/components';
import VerticalProductContent from './product-content';
import { Video } from './video';
import VerticalImage from './image';
import clsx from 'clsx';

export default function VerticalRecommendationBanner({
  data
}: {
  data: {
    attributes: VerticalRecommendationBannerType;
    products: Product[];
  };
}) {
  const isMobile = useMediaQuery('(max-width:768px)');
  const widgetData = data?.attributes?.recommendation_banner_dual;

  const imageLeft = isMobile
    ? widgetData?.kwargs?.value?.image_left_mobile?.url
    : widgetData?.kwargs?.value?.image_left?.url;
  const imageRight = isMobile
    ? widgetData?.kwargs?.value?.image_right_mobile?.url
    : widgetData?.kwargs?.value?.image_right?.url;
  const imageLeftAlt = widgetData?.value?.image_left_alt;
  const imageRightAlt = widgetData?.value?.image_right_alt;
  const imageLeftUrl = widgetData?.value?.image_url_left;
  const imageRightUrl = widgetData?.value?.image_url_right;
  const videoLeft = isMobile
    ? widgetData?.kwargs?.value?.video_left_mobile?.url
    : widgetData?.kwargs?.value?.video_left?.url;
  const videoRight = isMobile
    ? widgetData?.kwargs?.value?.video_right_mobile?.url
    : widgetData?.kwargs?.value?.video_right?.url;
  const videoOrImageLeft = widgetData?.value?.video_or_image_left;
  const videoOrImageRight = widgetData?.value?.video_or_image_right;
  const url = widgetData?.value?.url;
  const urlText = widgetData?.value?.url_text;
  const urlColor = widgetData?.value?.url_text_color;
  const widgetTitle = widgetData?.value?.widget_title;
  const widgetTitleColor = widgetData?.value?.widget_title_color;

  const firstGroup = data?.products?.slice(0, 2);
  const secondGroup = data?.products?.slice(2, 4);

  return (
    <div className="container">
      <div className="block w-full">
        <h2
          className={clsx("text-center text-2xl lg:text-[40px] pb-6 pt-10 lg:pt-[72px]",
            !url? 'pb-[54px]' : 'pb-4'
          )}
          style={{ color: widgetTitleColor }}
        >
          {widgetTitle}
        </h2>
        {url && (
          <Link
            className="pb-[30px] flex items-center justify-center gap-1 py-1 text-sm md:mb-14 md:text-base lg:my-2"
            style={{ color: urlColor }}
            href={url}
          >
            <div className="border-b-[1px] border-black p-0.5">{urlText}</div>
            <Icon name="arrow-right" size={24} className="hidden md:block" />
          </Link>
        )}
      </div>
      <div className="flex w-full xl:grid-cols-2 flex-col gap-4 xl:grid">
        <div className="grid grid-cols-1 items-start justify-start gap-4 md:grid-cols-2 lg::grid-cols-[324px_296px]">
          <div className="w-full xl:max-w-[324px]">
            {videoOrImageLeft === 'image' ? (
              <Link href={imageLeftUrl ? imageLeftUrl : '#'}>
                <VerticalImage src={imageLeft} alt={imageLeftAlt} />
              </Link>
            ) : (
              <Video
                src={videoLeft}
                className="object-cover h-[576px] max-h-[576px] w-full"
              />
            )}
          </div>

          <div className="w-full xl:max-w-[296px]">
            <VerticalProductContent data={firstGroup} />
          </div>
        </div>

        <div className="grid grid-cols-1 items-start justify-start gap-4 md:grid-cols-2 lg::grid-cols-[324px_296px]">
          <div className="w-full xl:max-w-[324px]">
            {videoOrImageRight === 'image' ? (
              <Link href={imageRightUrl ? imageRightUrl : '#'}>
                <VerticalImage src={imageRight} alt={imageRightAlt} />
              </Link>
            ) : (
              <Video
                src={videoRight}
                className="object-cover h-[610px] max-h-[610px] lg:h-[576px] lg:max-h-[576px] w-full"
              />
            )}
          </div>

          <div className="w-full xl:max-w-[296px]">
            <VerticalProductContent data={secondGroup} />
          </div>
        </div>
      </div>
    </div>
  );
}
